import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Spinner, Center } from '@chakra-ui/react';

import MainLayout from '@components/Layout';
import QRCard from '@components/Common/QRCard/V2';

import useGuestData from '@/hooks/useGuestData';
import useGuestInfo from '@/hooks/useGuestInfo';
import useRedirectToHomepage from '@/hooks/useRedirectToHomepage';

import { HOSTNAME } from '@/constants';
import { ENABLE_QR_INVITATION } from '@/constants/feature-flags';

export default function ETicket() {
  const { name: nameEticket, code: codeEticket } = useGuestInfo();
  const alreadyChecked = useRef(false);

  const [value, setValue] = useState('');
  const [isShowTicket, setIsShowTicket] = useState(false);
  // redirect to homepage if not eligible
  useRedirectToHomepage(!ENABLE_QR_INVITATION);

  const { data, loading } = useGuestData();

  const handleCheckTicket = useCallback(
    (tempValue) => {
      const guestCode = (tempValue || value).toUpperCase();
      const guest = data.find((g) => (g.code || '').toUpperCase() === guestCode);

      if (guest) {
        setIsShowTicket(true);
        return;
      }

      alert(`Maaf, Kode data ${guestCode} tidak ditemukan. Mohon di cek lagi..`);
      window.location.assign(HOSTNAME);
    },
    [data, nameEticket, value],
  );

  useEffect(() => {
    if (codeEticket !== '' && data.length > 0 && !alreadyChecked.current) {
      alreadyChecked.current = true;
      setValue(codeEticket);
      setTimeout(() => {
        handleCheckTicket(codeEticket);
      }, 1000);
    }
  }, [codeEticket, data.length, handleCheckTicket, loading]);

  /**
   * IF QR Feature not enable,
   * then return nothing
   */
  if (!ENABLE_QR_INVITATION) return null;

  return (
    <MainLayout>
      <Box minHeight="100vh">
        <div className="homepage">
          {isShowTicket ? (
            <Box padding="8px">
              <QRCard isDirectLink={false} />
            </Box>
          ) : (
            <Center height="100vh">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="mainColorText"
                size="xl"
                margin="auto"
              />
            </Center>
          )}
        </div>
      </Box>
    </MainLayout>
  );
}
